import React from 'react';
import ContentPageLayout from '../components/ContentPageLayout';
import PrivacyPolicy from '../components/PrivacyPolicy';

const Page = () => (
  <ContentPageLayout title="Política de Privacidad">
    <section className="ContentPageLayout-block u-margin-top">
      <div className="u-margin-top">
        <PrivacyPolicy />
      </div>
    </section>
  </ContentPageLayout>
);

export default Page;
