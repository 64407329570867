import React from 'react';
import { pure } from 'recompose';
import '../ContentPageLayout/ContentPageLayout.scss';
import './PrivacyPolicy.scss';

const PrivacyPolicy = () => (
  <div className="PrivacyPolicy">
    <ol className="ContentPageLayout-orderedList">
      <li className="ContentPageLayout-orderedListItem">
        <strong className="ContentPageLayout-strong">
          INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS
        </strong>
        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">
            ¿Quiénes son los responsables del tratamiento de tus datos?
          </strong>{' '}
          La entidad NBQ Fund One, S.L.U. y su intermediario financiero NBQ Technology, S.A.U.
        </p>
        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">
            ¿Con qué finalidad tratamos tus datos?
          </strong>{' '}
          Para gestionar y ejecutar tu solicitud y, en su caso, el contrato de financiación con
          acceso a ficheros de información crediticia. Asimismo, para elaborar perfiles crediticios
          y comerciales y, además, para gestionar el envío de comunicaciones comerciales propias y
          similares.
        </p>
        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">
            ¿Con qué base legitimadora tratamos tus datos?
          </strong>{' '}
          La ejecución del contrato, el interés legítimo, tu consentimiento y obligaciones legales.
        </p>
        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">¿A quién entregamos tus datos?</strong> A los
          prestadores de servicios que actúen como encargados del tratamiento y a los ficheros de
          solvencia crediticia.
        </p>
        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">¿Cuáles son tus derechos?</strong> El derecho
          de acceso, rectificación, supresión y otros más, se explican en la información adicional
          que encontrarás a continuación.
        </p>
      </li>
      <li className="ContentPageLayout-orderedListItem">
        <strong className="ContentPageLayout-strong">
          INFORMACIÓN ADICIONAL Y DETALLADA SOBRE PROTECCIÓN DE DATOS
        </strong>
        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">
            ¿Quiénes son los responsables del tratamiento de tus datos?
          </strong>{' '}
          Los responsables del tratamiento de tus datos son las siguientes entidades:
        </p>
        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">NBQ TECHNOLOGY, S.A.U.</strong>{' '}
          (intermediario financiero de NBQ Fund One, S.L.U.)
          <br />
          C.I.F.: A-65559296
          <br />
          Dirección postal: Calle Jordi Girona, 29, P. 1. 08034, Barcelona (Barcelona). España.
          <br />
          Teléfono: +34 932 71 44 88
          <br />
          Teléfono: +34 900 83 11 63
          <br />
          Contacto Delegado Protección de Datos: dpo@quebueno.es
          <br />
        </p>

        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">NBQ Fund One, S.L.U.</strong>
          <br />
          C.I.F.: B--66128695
          <br />
          Dirección postal: Calle Jordi Girona, 29, P. 1. 08034, Barcelona (Barcelona). España.
          <br />
          Teléfono: +34 932 71 44 88
          <br />
          Teléfono: +34 900 83 11 63
          <br />
          Contacto Delegado Protección de Datos: dpo@quebueno.es
        </p>
        <br />
        <div className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">
            ¿Con qué finalidad tratamos tus datos?
          </strong>{' '}
          NBQ Technology, S.A.U., en calidad de intermediario financiero de la entidad NBQ Fund One,
          S.L.U., trata la información que tu nos ha facilitado con el siguiente fin principal:
          <ul className="ContentPageLayout-list">
            <li className="ContentPageLayout-listItem">
              Para{' '}
              <strong className="ContentPageLayout-strong">
                gestionar y ejecutar su solicitud de financiación
              </strong>{' '}
              a través del sitio web www.quebueno.es titularidad de NBQ Technology, S.A.U. y, en
              consecuencia, informarle del resultado derivado de la gestión de dicha solicitud, esto
              es, si ha sido aprobada o rechazada. En este sentido, para cumplir con la anterior
              finalidad, puesta en relación con la obligación de concesión de préstamos
              responsables, NBQ Technology, S.A.U. realiza asimismo los siguientes tratamientos con
              sus datos:
              <ul className="ContentPageLayout-list">
                <li className="ContentPageLayout-listItem">
                  Consultar sus datos y sus antecedentes crediticios en los ficheros relativos al
                  cumplimiento e incumplimiento de obligaciones dinerarias, financieras o de crédito
                  de Asnef-Equifax, todo ello a los efectos de evaluar su solvencia crediticia.
                </li>
                <li className="ContentPageLayout-listItem">
                  Elaborar perfiles crediticios que ayuden igualmente a evaluar su solvencia
                  patrimonial y de crédito a los efectos de gestionar su solicitud de financiación.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="ContentPageLayout-text">
          Asimismo, otros fines para los que NBQ Technology, S.A.U. trata tus datos son los
          siguientes:
          <ul className="ContentPageLayout-list">
            <li className="ContentPageLayout-listItem">
              Para remitirte comunicaciones comerciales, incluso por medios electrónicos, relativas
              a productos y servicios financieros propios y similares de NBQ Tecnology, S.A.U. que
              puedan ser de tu interés y relacionados con los que ya tienes contratados o, en su
              caso, con los que en su momento tuviste con nosotros.
            </li>
            <li className="ContentPageLayout-text">
              Para la remisión de las comunicaciones comerciales propias antes mencionadas, podemos
              llevar a cabo la elaboración de perfiles comerciales en base a la información
              proporcionada por tí y de la que ya dispone NBQ Technology, S.A.U. todo ello con los
              siguientes fines:
              <ul className="ContentPageLayout-list">
                <li className="ContentPageLayout-listItem">
                  Personalizar las ofertas comerciales propias de NBQ Technology, S.A.U. que
                  consideramos de interés para tí, relacionadas con los productos que tienes
                  contratados o que en su momento tuviste con nosotros.
                </li>
                <li className="ContentPageLayout-listItem">Mejorar su experiencia de usuario.</li>
              </ul>
            </li>
            <li>
              Para la prevención del fraude y el blanqueo de capitales mediante la solicitud de
              información y datos personales relativos a tu persona y, en concreto, a tu actividad y
              situación económico, laboral y profesional.
            </li>
          </ul>
        </div>
        <p className="ContentPageLayout-text">
          Por último, y con respecto a NBQ Fund One, S.L.U. esta entidad trata la información y los
          datos que tu has facilitado, una vez ha sido aceptada tu solicitud de financiación, con el
          fin de gestionar y ejecutar tu contrato de financiación concedido.
        </p>
        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">
            ¿Por cuánto tiempo conservaremos tus datos?
          </strong>{' '}
          Los datos se conservarán mientras se mantenga la relación contractual y, una vez
          finalizada, durante los años necesarios para cumplir con las obligaciones legales
          existentes. No obstante, si se solicita la supresión de los datos, y ello procede, se
          bloquearán los mismos para cumplir con las obligaciones legales existentes.
        </p>
        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">
            ¿Cuál es la legitimación para el tratamiento de tus datos?
          </strong>{' '}
          Con respecto a NBQ Technology, S.A.U., la base legal para el tratamiento principal de tus
          datos es la ejecución de la solicitud de financiación solicitada por ti en los términos y
          condiciones establecidos en la misma a través del sitio web www.quebueno.es titularidad de
          NBQ Technology, S.A.U.
        </p>
        <p className="ContentPageLayout-text">
          En este sentido, por lo que a los tratamientos relacionados directamente con la ejecución
          de la solicitud de financiación se refiere, como son la elaboración de perfiles
          crediticios (scoring) y la consulta a ficheros de solvencia crediticia (todo ello para
          evaluar tu situación patrimonial y de crédito y poder en su caso concederte la
          financiación en base a una política de préstamos responsable), la base legal para el
          tratamiento es la propia ejecución de la solicitud de financiación así como la existencia
          de una obligación legal que se concreta en la Ley 16/2011, de 24 de junio, de Contratos de
          Crédito al Consumo y en la Ley 2/2009, de 31 de marzo, de servicios de intermediación
          financiera para la celebración de contratos de préstamo o crédito.
        </p>
        <div className="ContentPageLayout-text">
          Asimismo, con respecto al resto de finalidades, la base legal para el tratamiento de sus
          datos es la siguiente:
          <ul className="ContentPageLayout-list">
            <li className="ContentPageLayout-listItem">
              Para la remisión de comunicaciones comerciales propias y similares, incluso por medios
              electrónicos, la legitimación para el tratamiento de tus datos es el interés legítimo
              que tiene NBQ Technology, S.A.U. como intermediaria financiera en crédito al consumo,
              en mantenerte informado en todo momento de los productos y servicios financieros de
              los que dispone y que pueden ser de tu interés.
            </li>
            <li className="ContentPageLayout-listItem">
              Para la elaboración de perfiles comerciales la legitimación para el tratamiento de tus
              datos es el interés legítimo sin que en ningún caso la retirada de este consentimiento
              condicione la ejecución de tu solicitud. En este sentido, durante el proceso de
              contratación se pone a su disposición el derecho de oposición a recibir este tipo de
              comunicaciones comerciales.
            </li>
            <li className="ContentPageLayout-listItem">
              Para la prevención del fraude y el blanqueo de capitales, la legitimación para el
              tratamiento de tus datos es una obligación legal aplicable a NBQ Technology, S.A.U. y
              que se concreta en la Ley 10/2010, de 28 de abril, de prevención del blanqueo de
              capitales y de la financiación del terrorismo así como en su reglamento de desarrollo
              (RD 304/2014).
            </li>
          </ul>
        </div>
        <p className="ContentPageLayout-text">
          Con respecto a NBQ Fund One, S.L.U. la base legal para el tratamiento principal de tus
          datos es la ejecución de tu contrato de financiación aceptado y formalizado por ti.
        </p>
        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">
            ¿A qué destinatarios se comunicarán sus datos?
          </strong>{' '}
          Los datos se comunicarán a los ficheros relativos al cumplimiento e incumplimiento de
          obligaciones dinerarias, financieras o de crédito de Asnef-Equifax. Asimismo, se
          comunicarán los datos a aquellos prestadores de servicios con los que los responsables del
          tratamiento mantengan acuerdos suscritos y que actúen como Encargados del Tratamiento de
          los datos.
        </p>
        <p className="ContentPageLayout-text">
          <strong className="ContentPageLayout-strong">
            ¿Cuáles son tus derechos cuando nos facilitas tus datos?
          </strong>
        </p>
        <div className="ContentPageLayout-text">
          Los derechos que tus tienes desde el momento de facilitar stusus datos son los siguientes:
          <ul className="ContentPageLayout-list">
            <li className="ContentPageLayout-listItem">
              <strong className="ContentPageLayout-strong">Derecho de Información</strong>:
              Cualquier persona tiene derecho a obtener confirmación sobre si estamos tratando datos
              personales que le conciernen o no.
            </li>
            <li className="ContentPageLayout-listItem">
              <strong className="ContentPageLayout-strong">
                Derechos de Acceso, Rectificación y Supresión
              </strong>
              : las personas interesadas tienen derecho a acceder a sus datos personales así como a
              solicitar la rectificación de los datos inexactos o, en su caso, solicitar su
              supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines
              que fueron recogidos.
            </li>
            <li className="ContentPageLayout-listItem">
              <strong className="ContentPageLayout-strong">Derecho de Limitación</strong>: en
              determinadas circunstancias, los interesados podrán solicitar la limitación del
              tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio
              o la defensa de reclamaciones.
            </li>
            <li className="ContentPageLayout-listItem">
              <strong className="ContentPageLayout-strong">Derecho de Oposición</strong>: asimismo,
              en determinadas circunstancias y por motivos relacionados con su situación particular,
              los interesados podrán oponerse al tratamiento de sus datos. En este caso, se dejarán
              de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la
              defensa de posibles reclamaciones.
            </li>
            <li className="ContentPageLayout-listItem">
              <strong className="ContentPageLayout-strong">Derecho de Portabilidad</strong>: del
              mismo modo, los interesados podrán solicita en el caso de que sea posible la
              portabilidad de sus datos a otros proveedores de servicios siempre que previamente se
              hayan utilizado o tratado con su consentimiento o por existir un contrato.
            </li>
          </ul>
        </div>
        <div className="ContentPageLayout-text">
          En cuanto a la forma de cómo ejercer estos derechos, tu puedes dirigirte directamente a
          NBQ Technology, S.A.U. (actuando ésta como responsable del tratamiento y a su vez como
          encargado de NBQ Fund One, S.L. para estas gestiones) en los siguientes términos
          (aportando siempre copia de tu documento de identidad):
          <ul className="ContentPageLayout-list">
            <li className="ContentPageLayout-listItem">
              Por escrito a NBQ Technology, S.A.U., Calle Jordi Girona, 29, P. 1. 08034, Barcelona
              (Barcelona). España; o bien,
            </li>
            <li className="ContentPageLayout-listItem">Por e-mail a dpo@quebueno.es</li>
          </ul>
        </div>
        <p className="ContentPageLayout-text">
          En todo caso, tu tienes derecho a retirar en cualquier momento el consentimiento prestado.
          Asimismo, tu tienes derecho a presentar reclamación ante la Agencia Española de Protección
          de Datos (AEPD).
        </p>
      </li>
    </ol>
  </div>
);

export default pure(PrivacyPolicy);
